<template>
  <WeContainer card="" v-if="ready">
    <WeCard class="sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="m-0"><i class="fa fa-wave-square"></i> {{ getTitle }}</h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="form-group mb-3">
            <label class="custom-label">Tedarikçi Seç</label>
            <v-select
              label="name"
              v-bind:value="form.supplier"
              v-bind:options="supplier.list"
              v-bind:reduce="(opt) => opt.id"
              v-bind:clearable="true"
              v-on:input="onInput"
              class="setting-select"
            >
            </v-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="custom-label">Durum</label>
            <WeSwitch v-model="form.is_active" label="Aktif" />
          </div>
        </div>
      </div>
    </WeCard>
    <WeCard class="mt-3" v-if="supplierStatus != 0">
      <WeCard class="alert-primary" v-if="supplierStatus != 4">
        <i class="fas fa-info-circle"></i>
        <span v-if="supplierStatus == 1">
          Seçilen tedarikçiye ait bir XML şablonu bulunamadı. Lütfen
          <b>{{ selectedSupplier.name }}</b> tedarikçisi için bir şablon
          <a href="/xml-template/new"> oluşturunuz</a> veya oluşturulmuş
          şablonlardan birini
          <a :href="'supplier-integration/detail/' + selectedSupplier.id"
            >seçiniz.
          </a></span
        >
        <span v-else-if="supplierStatus == 2">
          Tedarikçinin kullandığı XML şablonunda henüz eşleştirme
          yapılmamış.</span
        >
        <span v-else-if="supplierStatus == 3">
          Tedarikçinin kullandığı XML şablonunda Kategori Adı alanı için
          eşleştirme yapılmamış.</span
        >
      </WeCard>
      <template v-else>
        <div class="row">
          <div class="col-12 col-lg-12">
            <h5>Kategori Listesi</h5>
            <button
              type="submit"
              v-on:click="clickLoadCatData"
              class="btn btn-info float-right mb-2 ml-2"
              :disabled="!isXmlReady || loadingCategory"
            >
              <i
                :class="
                  isXmlReady ? 'fa fa-download' : 'fas fa-spinner fa-spin'
                "
              ></i>
              {{
                isXmlReady
                  ? selectedSupplier.name + " Kategorilerini Çek"
                  : selectedSupplier.name + " Kategorileri Çekiliyor"
              }}
            </button>
          </div>
        </div>

        <div class="row margin-0" v-if="XmlCategoryData.length > 0">
          <div class="col-12 col-lg-6 pad-10-10">
            <v-select
              label="name"
              size="sm"
              inputClass="col-12 col-lg-12"
              labelClass="display-none"
              v-bind:multiple="true"
              v-bind:value="selectedXmlCategory"
              v-bind:options="XmlCategoryData"
              v-bind:close-on-select="false"
              v-on:input="onXmlCategoryInput"
            />
          </div>
          <div class="col-12 col-lg-5 pad-10-10">
            <WeRowInput
              form="vMultipleSelect"
              size="sm"
              inputClass="col-12 col-lg-12"
              name="category"
              labelClass="display-none"
              placeholder="Kategori"
              v-bind:option-prop="category.list"
              v-model="selectedMainCategory"
            />
          </div>
          <div class="col-12 col-lg-1 pad-10-10">
            <button
              type="submit"
              class="btn btn-success float-right mb-2 ml-2"
              v-on:click="saveXmlCategory"
              :disabled="
                selectedXmlCategory.length <= 0 ||
                selectedMainCategory == null ||
                submit
              "
            >
              <i class="fa fa-plus"></i>
              Ekle
            </button>
          </div>
        </div>
      </template>
      <div class="col-12 col-lg-12" v-if="loadingCategory">
        <WeLoading />
      </div>
    </WeCard>
    <WeCard class="mt-3" v-if="supplierStatus == 4 && form.category.length > 0">
      <div class="row">
        <div class="col-12 col-lg-12">
          <WeRowInput
            size="sm"
            inputClass="col-12 col-lg-3 ml-auto"
            labelClass="display-none"
            placeholder="Ara..."
            v-bind:value="searchText"
            v-on:input="searchCategory($event)"
          />
        </div>
      </div>
      <table class="mt-2 table border straight table-hover">
        <thead class="thead-light">
          <tr>
            <th width="auto" class="sortable">
              <span>{{ selectedSupplier.name }} Kategorileri</span>
            </th>
            <th width="40%" class="sortable">
              <span>Mevcut Kategoriler</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in showFormCategory"
            v-bind:key="index"
            v-bind:index="index"
          >
            <td class="text-left">
              {{ item.xmlCategory.name }}
            </td>
            <td class="text-left">
              <WeRowInput
                form="vMultipleSelect"
                size="sm"
                inputClass="col-12 col-lg-12"
                name="category"
                labelClass="display-none"
                v-bind:clearable="false"
                placeholder="Kategori"
                v-bind:option-prop="category.list"
                v-model="item.mainCategory"
                v-on:input="changeSaveCategory"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-12 col-md-auto">
        <div class="row justify-content-end">
          <div class="col-12 col-md-auto">
            <ul class="pagination">
              <li
                class="page-item"
                v-on:click="goToPage(paginate.currentPage - 1)"
              >
                <span class="page-link"
                  ><i class="fas fa-chevron-left"></i
                ></span>
              </li>
              <li
                :class="
                  'page-item ' +
                  (paginate.currentPage == item.page ? 'active' : '')
                "
                v-for="(item, index) in paginate.pageLinks"
                v-bind:key="index"
                v-on:click="goToPage(item.page)"
              >
                <a class="page-link">{{ item.page }}</a>
              </li>
              <li
                class="page-item"
                v-on:click="goToPage(paginate.currentPage + 1)"
              >
                <span class="page-link"
                  ><i class="fas fa-chevron-right"></i
                ></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </WeCard>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      isXmlReady: true,
      loadingCategory: false,
      submit: false,
      currentPage: 1,
      showFormCategory: [],
      searchText: "",
      paginate: {
        pageCount: 0,
        minPer: 15,
        currentPage: 1,
        pageLinks: [],
      },
      isUpdate: false,
      supplierStatus: 0,
      selectedSupplier: null,
      selectedSupplierTemplateBrace: null,
      categoryXMLAttribute: "",
      selectedXmlCategory: [],
      selectedMainCategory: null,
      XmlCategoryData: [],
      form: {
        name: null,
        is_active: true,
        supplier: null,
        category: [],
      },
      tmpLang: null,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("supplier", [
      "create",
      "update",
      "getList",
      "loadXmlCategory",
      "saveCategories",
      "getSupplierCategory",
      "loadJsonCategory",
      "loadSupplierCategory",
    ]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.selectedSupplier) {
        return;
      }
      this.submit = true;
      this.updateSupplierCategory();
      this.isUpdate = false;
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Tedarikçi Entegrasyonları listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    updateSupplierCategory(saveForm = this.form.category) {
      this.submit = true;
      this.saveCategories({
        categories: saveForm,
        supplier: this.selectedSupplier,
        onSuccess: (result) => {
          this.clearForm();
          this.$swal({
            title: "Başarılı",
            text: "Ekleme işlemi başarılı",
            icon: "success",
          });
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    doSearch(text, timeout) {
      let localTimeout = 0;
      if (timeout) {
        localTimeout = timeout;
      }

      if (text && text.trim().length) {
        if (text.trim().length >= 3) {
          this.emitSearch(text, localTimeout);
        }
      } else {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          clearTimeout(this.timer);
          this.runSearch("", true);
        }, localTimeout);
      }
    },
    emitSearch(text, timeout) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.runSearch(text);
      }, timeout);
    },
    searchCategory(payload) {
      this.doSearch(payload, 600);
    },
    changeSaveCategory(payload) {
      this.isUpdate = true;
    },
    runSearch(text, is_null = false) {
      let result = [];
      if (is_null) result = this.form.category;
      else {
        result = this.form.category.map((value) => {
          return value.xmlCategory.name
            .toLowerCase()
            .indexOf(text.toLowerCase()) != -1
            ? value
            : undefined;
        });
      }
      this.paginate.currentPage = 1;
      this.loadFormCategory(result.filter((A) => A !== undefined));
    },
    goToPage(page) {
      if (page <= 0) {
        page = 1;
      } else if (page > this.paginate.pageCount) {
        page = this.paginate.pageCount;
      }
      this.paginate.currentPage = page;
      this.loadFormCategory(this.form.category);
      this.setPageLink();
    },
    loadFormCategory(data) {
      let first = (this.paginate.currentPage - 1) * this.paginate.minPer;
      let last = first + this.paginate.minPer - 1;
      last = last > data.length ? data.length : last;
      let categories = data.map((value, index) => {
        if (index >= first && index <= last) {
          return value;
        }
      });
      this.showFormCategory = categories.filter((A) => A !== undefined);
    },
    setPageCount() {
      let mod = this.form.category.length % this.paginate.minPer;
      let pageCount =
        this.form.category.length / this.paginate.minPer + (mod > 0 ? 1 : 0);
      this.paginate.pageCount = Math.floor(pageCount);
      this.setPageLink();
    },
    setPageLink() {
      this.paginate.pageLinks = helper.paginateLinks(
        this.paginate.pageCount,
        this.paginate.currentPage
      );
    },
    createCategoryList() {
      this.ready = false;
      this.getCategoryList({
        lang: this.language,
        onFinish: () => {
          this.ready = true;
          this.filteredCategoryList = this.getItems.map((item) => {
            return this.setSelectable(item);
          });
        },
      });
    },
    goPage(page) {
      if (page > this.pageCount || page <= 0) return;
      this.currentPage = page;
    },
    saveXmlCategory() {
      let saveForm = [];
      if (
        this.selectedXmlCategory.length <= 0 ||
        this.selectedMainCategory == null
      )
        return;
      this.selectedXmlCategory.forEach((element) => {
        let item = {
          xmlCategory: element,
          mainCategory: this.selectedMainCategory,
        };
        this.form.category.push(item);
        saveForm.push(item);
        this.setPageCount();
      });
      this.goToPage(this.paginate.currentPage);
      this.updateSupplierCategory(saveForm);
    },
    filterXmlCateogryData(data) {
      let arr = [];
      for (let item of data) {
        if (!this.form.category.find((A) => A.xmlCategory.id == item.id)) {
          arr.push(item);
        }
      }
      return arr;
    },
    clearForm() {
      this.XmlCategoryData = this.filterXmlCateogryData(this.XmlCategoryData);
      this.selectedXmlCategory = [];
      this.selectedMainCategory = null;
      this.loadingCategory = false;
      this.setPageCount();
    },
    clickLoadCatData() {
      if (this.selectedSupplier.type == 3) {
        this.loadJsonCategoryData();
      } else {
        this.loadXmlCategoryData();
      }
    },
    loadXmlCategoryData() {
      this.isXmlReady = false;
      this.XmlCategoryData = [];
      this.loadXmlCategory({
        address: this.selectedSupplier.address,
        attributeKey: this.categoryXMLAttribute,
        supplierId: this.selectedSupplier.id,
        brace: this.selectedSupplierTemplateBrace,
        onSuccess: (result) => {
          if (result.data.status == "error") {
            this.$swal({
              title: "Hata",
              text: result.data.message,
              icon: "error",
            });
          } else {
            this.XmlCategoryData = this.filterXmlCateogryData(result.data.data);
          }
          this.isXmlReady = true;
        },
      });
    },
    loadJsonCategoryData() {
      this.isXmlReady = false;
      this.XmlCategoryData = [];
      this.loadJsonCategory({
        name: this.selectedSupplier.name,
        supplier_id: this.selectedSupplier.id,
        onSuccess: (result) => {
          if (result.data.status == "error") {
            this.$swal({
              title: "Hata",
              text: result.data.message,
              icon: "error",
            });
          } else {
            this.XmlCategoryData = this.filterXmlCateogryData(result.data.data);
          }
          this.isXmlReady = true;
        },
      });
    },
    categoryList() {
      let categoryList = this.category.list;
      const result = [...categoryList];
      return result;
    },
    onXmlCategoryInput(payload) {
      this.selectedXmlCategory = payload;
    },
    onInput(value) {
      this.clearForm();
      this.form.supplier = value;
      let supplier = this.supplier.list.find((A) => A.id == this.form.supplier);
      this.selectedSupplier = supplier;
      this.supplierStatus = 4;
      this.loadingCategory = true;
      this.loadSupplierCategory({
        form: supplier,
        onSuccess: (result) => {
          supplier.categories = result.data;
          if (supplier.type == 3) {
            this.getSupplierCategory({
              supplier: this.selectedSupplier.id,
              onSuccess: (result) => {
                if (!supplier.categories) {
                  this.loadJsonCategoryData();
                } else {
                  this.XmlCategoryData = supplier.categories;
                  this.XmlCategoryData.sort();
                  this.setPageCount();
                }
                this.form.category = result.data;
                this.clearForm();
                this.goToPage(1);
              },
            });
          } else {
            if (supplier) {
              if (supplier.template === null) {
                this.supplierStatus = 1;
              } else {
                if (supplier.template.template) {
                  try {
                    let temp = JSON.parse(supplier.template.template);
                    if (temp) {
                      let is_category = temp.find(
                        (A) => A.key == "Category_name"
                      );
                      this.selectedSupplierTemplateBrace =
                        is_category.brace ?? "";
                      if (is_category) {
                        if (is_category.data.length > 0) {
                          this.categoryXMLAttribute = is_category.data;
                          this.supplierStatus = 4;
                          this.loadingCategory = true;
                          this.getSupplierCategory({
                            supplier: this.selectedSupplier.id,
                            onSuccess: (result) => {
                              if (!supplier.categories) {
                                this.loadXmlCategoryData();
                              } else {
                                this.XmlCategoryData = supplier.categories;
                                this.XmlCategoryData.sort();
                                this.setPageCount();
                              }
                              this.form.category = result.data;
                              this.clearForm();
                              this.goToPage(1);
                            },
                          });
                        } else {
                          this.supplierStatus = 3;
                        }
                      } else {
                        this.supplierStatus = 3;
                      }
                    }
                  } catch (e) {
                    this.supplierStatus = 2;
                  }
                } else {
                  this.supplierStatus = 2;
                }
              }
            }
          }
        },
      });
    },
    setChanged() {
      sessionStorage.setItem("changed", true);
    },
    removeChanged() {
      sessionStorage.removeItem("changed");
    },
    redirectToList() {
      this.$router.push("/supplier-integration");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    changeSlug(data) {
      this.form.slug = data;
    },
  },
  computed: {
    ...mapState(["supplier", "shared", "session", "category"]),
    getTitle() {
      return "Tedarikçi Kategori Eşleştirme";
    },
    submitButtonText() {
      return this.$route.params.id ? "Güncelle" : "Kaydet";
    },
    checkPermission() {
      return permission.check("brand", "u");
    },
  },
  mounted() {
    this.$store.dispatch("category/getList", "");
    this.getList({
      is_filter: ["categories", "template", ...this.supplier.defaultFilter],
      onSuccess: (result) => {
        this.ready = true;
      },
    });
  },
};
</script>
<style>
.pagination li:hover {
  cursor: pointer;
}
.pad-10-10 {
  padding: 10px 10px;
}
.margin-0 {
  margin-right: 0px;
  margin-left: 0px;
}
.vs__dropdown-menu {
  overflow: scroll !important;
}
</style>